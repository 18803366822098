import Grid from '@mui/material/Grid';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { alpha, Button, CircularProgress, FormControl, InputLabel, Menu, MenuItem, MenuProps, Paper, Select, Stack, styled } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import CateringRouteGrid from './CateringRouteGrid';
import { useEffect, useState } from 'react';
import 'dayjs/locale/de';
import { toast } from 'react-toastify';
import React from 'react';
import { useFetch } from '../../hooks/useFetch';
import { SourceMarketFields } from '@aviation/catering-masterdata-sdk';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function CateringRoutes() {
    const { t } = useTranslation();
    const { data: clientList = [] } = useFetch<Array<SourceMarketFields>>(`/api/masterdata/sourcemarkets/all`);
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([dayjs(), dayjs().add(7, "days")]);
    const [loading, setLoading] = useState(false);
    const [currentClientCode, setCurrentClientCode] = useState<string>('BNX');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let code = 'BNX'
        if ((clientList?.length ?? 0) > 0) {
            code = clientList[0].ClientCode ?? 'BNX';
        }

        code = localStorage.getItem('ClientCode') ?? code;
        setCurrentClientCode(code);
    }, [clientList]);

    const onChangeClientCode = (code: string | undefined) => {
        if (code === undefined)
            return;

        setCurrentClientCode(code);
        localStorage.setItem('ClientCode', code);
    }

    const downloadMealOrderFile = async (type: "csv" | "pdf") => {
        setLoading(true);
        handleClose();

        try {
            const response = await API.get('api', `/api/mealorder/${currentClientCode}/${type}/${dateRange[0]?.format("YYYY-MM-DD")}/${dateRange[1]?.format("YYYY-MM-DD")}`, {});
            window.location = response.url;
        } catch (error: any) {
            console.log(error.message, error)
            toast.error('An error occurred while generating meal order file.')
        } finally {
            setLoading(false);
        }
    }

    const downloadScheduleFile = async () => {
        setLoading(true);
        handleClose();

        try {
            const response = await API.get('api', `/api/cateringroutes/query/${currentClientCode}/from/${dateRange[0]?.format("YYYY-MM-DD")}/to/${dateRange[1]?.format("YYYY-MM-DD")}/export`, {});
            window.location = response;
        } catch (error: any) {
            console.log(error.message, error)
            toast.error('An error occurred while generating meal order file.')
        } finally {
            setLoading(false);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Catering Routes')} >
                    <Stack direction='row' spacing={2}>
                        {/* <LoadingButton loading={loading} variant="contained" onClick={() => downloadScheduleFile()}>Download Schedule</LoadingButton>
                        <LoadingButton loading={loading} variant="contained" onClick={() => downloadMealOrderFile("csv")}>Download CSV</LoadingButton>
                        <LoadingButton loading={loading} variant="contained" onClick={() => downloadMealOrderFile("pdf")}>Download PDF</LoadingButton> */}

                        <div>
                            <Button sx={{ height: 40, width: 120 }}
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClick}
                                disabled={loading || !currentClientCode}
                            >

                                {loading && <CircularProgress size="30px" />}
                                {!loading && 'Download'}
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{ 'aria-labelledby': 'demo-customized-button', }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => downloadMealOrderFile("csv")} disableRipple>
                                    CSV File
                                </MenuItem>
                                <MenuItem onClick={() => downloadMealOrderFile("pdf")} disableRipple>
                                    PDF File
                                </MenuItem>
                                <MenuItem onClick={() => downloadScheduleFile()} disableRipple>
                                    Schedule
                                </MenuItem>
                            </StyledMenu>
                        </div>
                        <div>
                            <FormControl sx={{minWidth:180}}>
                            <InputLabel id="client-select">Source Market</InputLabel>
                                <Select
                                    style={{ pointerEvents: 'all' }}
                                    labelId="client-select"
                                    value={currentClientCode}
                                    onChange={(e, c) => onChangeClientCode(e.target.value)}
                                    size='small'
                                    
                                    variant='outlined'
                                >
                                    {clientList.map(o => (
                                        <MenuItem value={o.ClientCode}>{o.ClientName}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>

                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en'>
                            <DateRangePicker value={dateRange} format='DD.MM.YYYY' onChange={setDateRange} slotProps={{ textField: { size: 'small' } }} />
                        </LocalizationProvider>
                    </Stack>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }}>
                    { currentClientCode && <CateringRouteGrid dateFrom={dateRange[0]?.format("YYYY-MM-DD")} dateTo={dateRange[1]?.format("YYYY-MM-DD")} clientCode={currentClientCode} /> }
                </Paper>
            </Grid>
        </Grid>
    );
}

export default CateringRoutes;