import { useTranslation } from 'react-i18next';
import { DataGridPremium, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { Status, useFetch } from '../../hooks/useFetch';
import { useMemo } from 'react';
import { CateringRouteFields, CateringRouteLeg } from '@aviation/catering-cateringrouteservice-sdk';
import { CatererFields, LoadingPlanFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';

export interface IDateValueProps {
    date?: Date;
}

function DateValue({ date }: IDateValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { dateStyle: "short" }, }) :
                    null
            }
        </>
    )
}

export interface ITimeValueProps {
    date?: Date;
}

function TimeValue({ date }: ITimeValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { val: { hour: 'numeric', minute: 'numeric', timeZoneName: "short" }, }, }) :
                    null
            }
        </>
    )
}

export interface ICateringRouteGridProps {
    showIncompleteRoutes: boolean;
}

function CateringRouteGrid({ showIncompleteRoutes = false, dateFrom="", dateTo="", clientCode='' }) {
    const { status, data = [] } = useFetch<Array<CateringRouteFields & DynamoDbObject>>(`/api/cateringroutes/query/${clientCode}/from/${dateFrom}/to/${dateTo}?ignoreWarnings=ignored`);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { status: catererStatus, data: catererData = [], error: catererError } = useFetch<Array<CatererFields & DynamoDbObject>>(`/api/masterdata/caterer`); // TODO: handle status
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { status: loadingPlanStatus, data: loadingPlanData = [], error: loadingPlanError } = useFetch<Array<LoadingPlanFields & DynamoDbObject>>(`/api/masterdata/loadingplan/${clientCode}`);
    
    const columns: GridColDef[] = [
        { field: 'DepDate', headerName: 'Date', minWidth: 100, renderCell: (params: GridRenderCellParams<Date>) => <DateValue date={new Date(params.value!)} /> },
        { field: 'Time', headerName: 'Time', minWidth: 140, valueGetter: (params: GridValueGetterParams) => getDepartureDate(params.row), renderCell: (params: GridRenderCellParams<Date>) => <TimeValue date={new Date(params.value!)} /> },
        { field: 'FlightNumbers', headerName: 'Flight No.', minWidth: 100 },
        { field: 'Route', headerName: 'Route', minWidth: 150 },
        { field: 'AcReg', headerName: 'Aircraft', minWidth: 50 },
        { field: 'LoadingStations', headerName: 'Loading', minWidth: 100 },
        { field: 'Caterer', headerName: 'Supplier', minWidth: 120 },
        { field: 'LoadingTypes', headerName: 'Loading Type', minWidth: 200 },
        { field: 'LoadingPlanName', headerName: 'Plan Type', minWidth: 120 },
        { field: 'IsDutyFree', headerName: 'Duty Free', minWidth: 100, type: 'boolean' },
        { field: 'HaulType', headerName: 'Level', minWidth: 50 },
        { field: 'Comment', headerName: 'Comment', minWidth: 50 },
    ];

    const getDepartureDate = (row : any) => {
        if(row.Legs !== undefined && row.Legs.length > 0) {
            return row.Legs[0].DepDate;
        }

        return row.DepDate;
    }

    const aggregatedData = useMemo(() => {
        if (!data || !catererData || !loadingPlanData) return [];
        let result = (data as any[]).filter(w => ((showIncompleteRoutes && w.Warnings !== undefined && w.Warnings.length > 0) || !showIncompleteRoutes) && (w.Legs?.length ?? 0) > 0).filter(x=>!x.RK.startsWith("LegIndex")).reduce((a, b) => [
            ...a,
            {
                ...b,
                Id: `${b.PK}#${b.RK}`,
                Path: `${b.PK}`,
                Al: b.Legs !== undefined && b.Legs.length > 0 ? b.Legs[0].Al ?? '' : '-',
                Route: b.Route ?? '-',
                AcReg: b.Legs !== undefined && b.Legs.length > 0 ? (Array.from(new Set(b.Legs.map((o : any) => o.AcReg))).join(', ')) : '-', //b.PK.split('#')[2],
                Date: b.PK.split('#')[3],
                DepDate: b.Legs !== undefined && b.Legs.length > 0 ? (b.Legs?.sort((a : any, b : any) => a.DepDate < b.DepDate ? -1 : 1)[0].DepDate) : "-",
                LoadingPlanName: ((loadingPlanData && loadingPlanData.find((d) => d.RK.split('#')[1] === b.LoadingPlanId)?.Name) || b.LoadingPlanId),
                Caterer: ((catererData && catererData.find((d) => d.RK === b.DefaultCatererId)?.Name) || b.DefaultCatererId),
                OutstationCaterer: ((catererData && catererData.find((d) => d.RK === b.OutstationCatererId)?.Name) || b.OutstationCatererId),
                Comment: b.Legs && b.Legs.map((l: CateringRouteLeg)=>l.Comment).filter((c: string | undefined)=>c).join(' / ')
            },
            ...b.Legs?.sort((a : any, b : any) => a.DepDate < b.DepDate ? -1 : 1).map((leg: any, i: number) => {
                const caterer = leg.Caterer.findLast((e: any) => !!e);
                const route = `${leg.Dep}-${leg.Dest}`;
                return {
                    Leg: true,
                    Id: `${b.PK}#${b.RK}.${route}${i}`,
                    Al: leg.Al,
                    FlightNumbers: `${leg.Al}${leg.Nr}`,
                    AcReg: b.Legs[i].AcReg,
                    PK: b.PK,
                    RK: route,
                    Route: route,
                    Date: b.Date,
                    DepDate: leg.DepDate,
                    LoadingStations: leg.LoadingStations,
                    LoadingTypes: caterer?.LoadingTypes?.join(', '),
                    IsDutyFree: b.IsDutyFree,
                    Caterer: (catererData && catererData.find((d) => d.RK === caterer?.CatererId)?.Name) || caterer?.Name,
                    Comment: leg.Comment ?? ''
                }
            }) ?? []
        ], []);

        return result
    }, [data, catererData, loadingPlanData, showIncompleteRoutes])

    return (

        <DataGridPremium
            treeData
            autoHeight
            rows={aggregatedData}
            columns={columns}
            // pageSize={10}
            // rowsPerPageOptions={[10]}
            pagination
            getRowId={(itm) => itm.Id}
            getTreeDataPath={(row) => row.Id.split('.')}
            getRowClassName={(params) => { return params.row.Leg === true ? `tui-grid-leg-row` : 'tui-grid-route-row'}}
            loading={status === Status.Fetching || status === Status.Idle}
            // error={error}
            groupingColDef={{
                headerName: ' ',
                valueGetter: (params: GridValueGetterParams) => '',
                width: 50,
                resizable: false,
                hideable: false,                
            }}
        />
    );
}

export default CateringRouteGrid;